import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import DataService from "../services/api/data_services";
import RightArrow from "../assets/Icons/go.svg";
import RightArrowWhite from "../assets/Icons/accordian_white.svg";
import clutchLogo from "../assets/Images/WhatWeDo/clutch-co-vector-logo.svg";
import Layout from "../components/layouts/layout";
import Typography from "@material-ui/core/Typography";
import GreyLine from "../components/GreyLine";
// Mobile movie slider
import MobileMovieSlider from "../components/MobileMovieSlider";
import BackArrowIcon from "../assets/Images/accordian.svg";
import Button from "../components/Button";
//Image Banner
import ImageBanner from "../templates/ImageBanner";
import MiniImageBannerBgImg12 from "../assets/Images/HowWeDoIt/who_we_work_with.png";
// import BannerBgImage from "../assets/Images/MobileApp/web-app-development-banner-image.png";
import BannerBgImage from "../assets/Images/MobileApp/web_dev_testing.jpg";
import { BASE_SITE_URL } from "../shared/constants";
import Megaview from "../templates/MegaView";

//Info View
import Infoview from "../templates/InfoView";

import Angular from "../assets/Images/MobileApp/Weblanguages_wespeak/Angular.png";
import ReactJS from "../assets/Images/MobileApp/Weblanguages_wespeak/ReactJS.png";
import Vue from "../assets/Images/MobileApp/Weblanguages_wespeak/Vue.png";
import EmberJS from "../assets/Images/MobileApp/Weblanguages_wespeak/EmberJS.png";
import Rails from "../assets/Images/MobileApp/Weblanguages_wespeak/Rails.png";
import Python from "../assets/Images/MobileApp/Weblanguages_wespeak/Python.png";
import Php from "../assets/Images/MobileApp/Weblanguages_wespeak/Php.png";
import DotNet from "../assets/Images/MobileApp/Weblanguages_wespeak/Dot_Net.png";

//Miniview
import Miniview from "../templates/MiniView";

// Two Column Contact info
import TwoColumnContactInfo from "../templates/TwoColumnContactInfo";
import Clm1Img from "../assets/Icons/phone.svg";
import Clm2Img from "../assets/Icons/mail.svg";

//Mini Image Banner 1
import MiniImageBanner1 from "../templates/MiniImageBanner1";

//BlurCardList
import BlurCardList from "../templates/BlurCardList";
import MiniCardBgImage1 from "../assets/Images/Homepage/mobile_application_development.png";
import MiniCardBgImage3 from "../assets/Images/Homepage/full_product_development.png";
import MiniCardBgImage4 from "../assets/Images/Homepage/MVP.png";
import MiniCardBgImage5 from "../assets/Images/Homepage/Product_designing_and_Prototyping.png";
import MiniCardBgImage6 from "../assets/Images/Homepage/product_scaling.png";
import MiniCardBgImage7 from "../assets/Images/Homepage/code_ux_audit.png";
import MiniCardBgImage8 from "../assets/Images/Homepage/digital_transformation.png";
import MiniCardBgImage9 from "../assets/Images/Homepage/machine_learning.png";

//Click To View Mega ViewCarousal
import ClickToViewMegaViewCarousal from "../templates/ClickToViewMegaViewCarousal";
import ClickToViewMegaViewCarousalImg1 from "../assets/Images/ClickToViewMegaViewCarousal/Lean_startup_mindset.png";
import ClickToViewMegaViewCarousalImg2 from "../assets/Images/ClickToViewMegaViewCarousal/Contact_and_first_proposal.png";
import ClickToViewMegaViewCarousalImg3 from "../assets/Images/ClickToViewMegaViewCarousal/Product_Workshops_with_the_team.png";
import ClickToViewMegaViewCarousalImg4 from "../assets/Images/ClickToViewMegaViewCarousal/Final_proposal_and_release_plan.png";

//Mobile/Web Application Development
import ogimage from "../assets/Images/ogImages/OG_Web-App-Dev.png";
import frontEnd from "../assets/Images/MobileApp/Group_6252_2x.png";
import backEnd from "../assets/Images/MobileApp/Group_6256_2x.png";

const WebApplicationDevelopment = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [articleRawData, setArticleRawData] = useState([]);
  const [articleLimit, setArticleLimit] = useState(6);
  const [articlePage, setArticlePage] = useState(1);
  const [articleData, setArticleData] = useState([]);
  const [megaviewData, setMegaViewData] = useState([]);
  const [clientStoryData, setClientStoryData] = useState([]);
  const [clientStoryRawData, setClientStoryRawData] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const toggleHover = () => {
    setIsHover(!isHover);
  };
  useEffect(() => {
    DataService.getData(
      "/admin/api/all-services?limit=1&type=White Paper&service_type=PD - Web Application Development"
    ).then((response) => {
      setMegaViewData(response);
    });
  }, []);
  useEffect(() => {
    DataService.getData(
      `/admin/api/all-services?limit=${articleLimit}&page=${articlePage}&type=Article&service_type=PD - Web Application Development&component=mini_view`
    ).then((response) => {
      setArticleData([...articleData, ...response.data]);
      setArticleRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleLimit, articlePage]);
  useEffect(() => {
    DataService.getData(
      `/admin/api/all-services?limit=${limit}&page=${page}&type=Client Story&service_type=PD - Web Application Development&component=mini_view`
    ).then((response) => {
      setClientStoryData([...clientStoryData, ...response.data]);
      setClientStoryRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit]);
  /**
   * Image Banner
   */
  const ImageBannerData = {
    bnrImg: BannerBgImage,
    bnrImgTitle: (
      <>
        Web <br /> Application <br /> Development
      </>
    ),
    bnrImgSubTitle: "How can we help you today?",
    servicesColumn1: {
      data: [
        {
          service: "Mobile Application Development",
          link: "/mobile-application-development",
        },

        {
          service: "MVP Development",
          link: "/mvp-development",
        },
        {
          service: "Code & UX Audit",
          link: "/code-ux-audit",
        },
      ],
    },
    servicesColumn2: {
      data: [
        {
          service: "Web Application Development",
          link: "/web-application-development",
        },
        {
          service: "Product Designing and Prototyping",
          link: "/product-designing-and-prototyping",
        },
        {
          service: "Digital Transformation",
          link: "/digital-transformation",
        },
      ],
    },
    servicesColumn3: {
      data: [
        {
          service: "Full Product Development",
          link: "/full-product-development",
        },
        {
          service: "Product scaling",
          link: "/product-scaling",
        },
        {
          service: "Machine Learning",
          link: "/machine-learning",
        },
      ],
    },
  };

  /**
   * Infoview
   */
  const InfoviewData = {
    InfoviewTitle: (
      <div className="mbl-br-hide ttc">
        From an Intuitive Website, <br />
        <span className="red-line3letter">to a</span> Complex Web App
      </div>
    ),
    InfoviewContent: (
      <>
        We create custom web applications that are designed to meet business
        challenges, promote growth, and achieve your business goals. We use
        adaptive web applications and keep the end-users in mind to simplify
        complex business workflows. At Digiryte, we have an expert panel
        consisting of frontend developers, backend developers, designers, QAs,
        business analysts and scrum masters who deliver the best output for your
        digital product.
      </>
    ),
  };

  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  /**
   * Mini Image Banner 1
   */

  const MiniImageBanner1Data = {
    title: (
      <>
        Who we work <br /> with
      </>
    ),
    titleClass: "mini-image-banner-title-area3 title-header2",
    image: MiniImageBannerBgImg12,
    altTxt: "Who we work with",
    btnClassName: "takelook-image-banner-resize-btn",
    btnLabel: "TAKE A LOOK",
    btnVariant: "contained",
    btnColor: "primary",
    tooltip: "yes",
    tip: "TAKE A LOOK",
    route: "/who-we-work-with",
    underline: true,
    underlineClass: "black-line",
  };
  /**
   * Click To View Mega View Carousal
   */

  const ClickToViewMegaViewCarousalData = {
    data: [
      {
        title: "STEP 1: Lean startup approach",
        download: "no",
        bottom: "Collaboration Process",
        displayFIndoutMore: "no",
        card1data: {
          meta_title: "Lean startup approach",
          type: "ARTICLE",
          download: "no",
          height: "294px",
          primary_description_1:
            "Having a business mind is a skill. No skill is developed by tactics alone. Developing a skill at a very elite level requires behavioural changes, mindset shifts and expanding your personality. And it can take years to develop it. If you blindly follow tactics without the proper mindset, you won’t be executing those tactics the way they ought to be executed.  Let's take an example: Build, Iterate and Measure are the key concepts of Lean startup. There is nothing wrong with this concept. But let’s dig a few layers deeper to really understand what it means. If you directly apply this concept it will not work.",
          mega_view_image: ClickToViewMegaViewCarousalImg1,
          MegaviewFooterLabel: "ARTICLE",
          MegaviewButtonLabel: "Find out More",
          MegaviewButtonColor: "primary",
          MegaviewContentClassname:
            "megaview-card-content megaview-carousal-height ",
          MegaviewImageClassname: "megaview-carousal-height ",
          slug: "lean-mindset-to-launch-a-successful-software-product",
          slug_type: "article",
        },
      },
      {
        title: "STEP 2: Contact and first proposal",
        card1data: {
          meta_title: "Contact and first proposal",
          type: "ARTICLE",
          download: "no",
          height: "294px",
          primary_description_1:
            "After you contact Digiryte, we gather as much information possible about the product idea and spend our time understanding your business needs and identifying your potential customers to ensure we build a platform which will be familiar to your potential users and stands ahead of your competitors. After considering all the factors, we aim to respond in the next 2-3 business days with a pre-proposal which usually consists of a median SDLC ballpark estimate, the bespoke development approach we created based on your requirements and the various steps explaining our working methodology.",
          mega_view_image: ClickToViewMegaViewCarousalImg2,
          MegaviewFooterLabel: "ARTICLE",
          MegaviewButtonLabel: "Find out More",
          MegaviewButtonColor: "primary",
          MegaviewContentClassname:
            "megaview-card-content megaview-carousal-height ",
          MegaviewImageClassname: "megaview-carousal-height ",
          slug: "digiryte-s-proposal-and-working-methodology",
          slug_type: "article",
        },
      },
      {
        title: "STEP 3: Product workshop and consulting",
        card1data: {
          meta_title: "Product workshop and consulting",
          type: "ARTICLE",
          download: "no",
          height: "294px",
          primary_description_1:
            "Whether you have an idea for building a mobile app, or you need to build complex internal software in a large corporation, all great digital products should be built from a solid foundation. This means gaining a deep understanding of your business needs, what problem or opportunity you’re trying to solve, an idea of your users behaviours and preferences and the value that the product will add.",
          mega_view_image: ClickToViewMegaViewCarousalImg3,
          MegaviewFooterLabel: "ARTICLE",
          MegaviewButtonLabel: "Find out More",
          MegaviewButtonColor: "primary",
          MegaviewContentClassname:
            "megaview-card-content megaview-carousal-height ",
          MegaviewImageClassname: "megaview-carousal-height ",
          slug: "4-steps-of-digiryte-s-successful-product-workshop-methodology",
          slug_type: "article",
        },
      },
      {
        title: "STEP 4: Final proposal and release plan",
        card1data: {
          meta_title: "Final proposal and release plan",
          type: "ARTICLE",
          download: "no",
          height: "294px",
          primary_description_1:
            "We position ourselves uniquely in the market by taking a higher approach to creating a win-win situation for us and the client to avoid major pitfalls during the development. Having worked closely with the University of Manchester, over time, we have researched and created various methodologies which help our clients and us understand the project workflow better and help us meet expectations, costs and timelines more accurately. Based on the medium (mobile, web or both) and scope of the project, we usually narrow down at least two to three possible methodologies which best suit the flow of the project.",
          mega_view_image: ClickToViewMegaViewCarousalImg4,
          MegaviewFooterLabel: "ARTICLE",
          MegaviewButtonLabel: "Find out More",
          MegaviewButtonColor: "primary",
          MegaviewContentClassname:
            "megaview-card-content megaview-carousal-height ",
          MegaviewImageClassname: "megaview-carousal-height ",
          slug: "final-proposal-and-release-plan-methodology-of-digiryte",
          slug_type: "article",
        },
      },
    ],
  };

  /**
   * Blur Card List Data
   */

  const BlurCardListData = {
    cardsData: [
      {
        image: MiniCardBgImage1,
        title: "Mobile Application Development",
        subTitle:
          "Take business to your customers by building advanced native and hybrid mobile apps for both Android and iOS Platforms.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mobile-application-development",
      },
      {
        image: MiniCardBgImage3,
        title: "Full Product Development",
        subTitle:
          "The process of full product development encompasses all steps needed to take your product from concept to market place.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/full-product-development",
      },
      {
        image: MiniCardBgImage4,
        title: "MVP Development",
        subTitle:
          "Build a basic model to demonstrate the core functionality of your product, test it with early adopters and fulfil the primary goal of your business.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mvp-development",
      },
      {
        image: MiniCardBgImage5,
        title: "Product Designing and Prototyping",
        subTitle:
          "Create a prototype of your product to visualise your idea and generate feedback without incurring the building costs of a fully functioning system.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-designing-and-prototyping",
      },
      {
        image: MiniCardBgImage6,
        title: "Product Scaling",
        subTitle:
          "Found your product-market fit? Great! Let us help you with a profitable scaling process that provides both sustainability and stability for your product.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-scaling",
      },
      {
        image: MiniCardBgImage7,
        title: "Code & UX Audit",
        subTitle:
          "With a regular audit, we can help you fix your product, improve its performance, gain new users, and boost your brand reputation in the marketplace.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/code-ux-audit",
      },
      {
        image: MiniCardBgImage8,
        title: "Digital Transformation",
        subTitle:
          "Update your traditional business processes and customer experience to meet the ever-changing market requirements with the help of next-generation digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/digital-transformation",
      },
      {
        image: MiniCardBgImage9,
        title: "Machine Learning",
        subTitle:
          "Through machine learning, we can help you make informed decisions to improve business scalability and operational efficiency.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/machine-learning",
      },
    ],
    lastCardData: {
      title: "Let's Talk",
      subTitle: "We'd love to help with your product.",
      variant: "contained",
      label: "GET IN TOUCH",
      customClass: "miniCrdBgImg-main-border",
      route: "/contact-us",
    },
  };

  /**
   * Miniview
   */
  const quotedMiniCardData = {
    title: "Mobile App Dev for Automative Sales App",
    heading:
      "We can’t speak highly enough of Digiryte’s team because they bent over backward to help us launch our product.",
    content: "Founder, Bumper UK",
    footerContent: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>Authentic client review from</div>
        <div style={{ paddingLeft: "5px" }}>
          <img src={clutchLogo} alt="Clutch" height="50px" />
        </div>
      </div>
    ),
  };

  const miniviewDataArticle = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    tooltip: "yes",
    tip: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };
  const miniviewDataCaseStudy = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    tooltip: "yes",
    tip: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };

  // Mobile movie slider
  const mobMovieSlider = {
    imageUrl: frontEnd,
  };
  const mobMovieSlider1 = {
    imageUrl: backEnd,
  };
  return (
    <Layout
      seo={{
        title: "What we do: Web Application Development | Digiryte",
        ogtitle: "What we do: Web Application Development | Digiryte",
        description:
          "At Digiryte, we create custom web applications that are designed to meet business challenges, promote growth, and achieve your business goals.",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}/web-application-development`,
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-70 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="What we do"
          routepath="/what-we-do"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          What we do
        </Typography>
      </div>
      <div className="mt-30"></div>
      <Infoview InfoviewData={InfoviewData} />
      <div className="mt-70 body-container">
        <h2 className="title-header ttc">
          <span className="red-line3letter">Our</span> Collaboration Process
        </h2>
      </div>
      <div className="mt-70"></div>
      <ClickToViewMegaViewCarousal
        ClickToViewMegaViewCarousalData={ClickToViewMegaViewCarousalData}
      />
      <div className="mt-70 body-container">
        <h2 className="title-header ttc">
          <span className="red-line3letter">Web</span> Languages We speak!
        </h2>
        <div className="mt-50"></div>
        <h2 className="sub-header">Front-End Development</h2>
        <MobileMovieSlider movieSlider={mobMovieSlider} slideid="slide1" />
        <Grid container className="mt-20 mbl-hide" direction="row" spacing={1}>
          <Grid item sm={6} md={3}>
            <img alt="Flutter" src={Angular} />
            <p style={{ fontSize: "16px" }}>Angular</p>
          </Grid>
          <Grid item sm={6} md={3}>
            {" "}
            <img alt="xamarin" src={ReactJS} />
            <p style={{ fontSize: "16px" }}>React.js</p>
          </Grid>
          <Grid item sm={6} md={3}>
            {" "}
            <img alt="ReactNative" src={Vue} />
            <p style={{ fontSize: "16px" }}>Vue.js</p>
          </Grid>
          <Grid item sm={6} md={3}>
            {" "}
            <img alt="ReactNative" src={EmberJS} />
            <p style={{ fontSize: "16px" }}>Ember.js</p>
          </Grid>
        </Grid>
        <GreyLine />
        <div className="mt-50"></div>
        <h2 className="sub-header">Back-End Development</h2>
        <MobileMovieSlider movieSlider={mobMovieSlider1} slideid="slide2" />
        <Grid container className="mt-20 mbl-hide" direction="row" spacing={1}>
          <Grid item sm={6} md={3}>
            <img alt="Flutter" src={Rails} />
            <p style={{ fontSize: "16px" }}>Ruby on Rails</p>
          </Grid>
          <Grid item sm={6} md={3}>
            {" "}
            <img alt="xamarin" src={Python} />
            <p style={{ fontSize: "16px" }}>Python</p>
          </Grid>
          <Grid item sm={6} md={3}>
            {" "}
            <img alt="ReactNative" src={Php} />
            <p style={{ fontSize: "16px" }}>PHP</p>
          </Grid>
          <Grid item sm={6} md={3}>
            {" "}
            <img alt="ReactNative" src={DotNet} />
            <p style={{ fontSize: "16px" }}>.NET core</p>
          </Grid>
        </Grid>
      </div>
      <div className="mt-70">
        <MiniImageBanner1
          id="one"
          MiniImageBanner1Data={MiniImageBanner1Data}
        />
      </div>
      {clientStoryData && clientStoryData.length > 0 && (
        <div className="mt-70">
          <div className="body-container">
            <h3 className="title-header ttc">
              <span className="red-line3letter">Cli</span>ent Stories
            </h3>
          </div>
          <div className="mt-50">
            <Miniview
              miniviewData={clientStoryData}
              total={clientStoryData.length}
              miniviewData2={miniviewDataCaseStudy}
              quotedMiniCardData={quotedMiniCardData}
            />
          </div>
        </div>
      )}
      <div style={{ width: "100%", textAlign: "center" }}>
        <div
          onMouseEnter={toggleHover}
          onMouseLeave={toggleHover}
          className="mt-60"
          role="presentation"
          onClick={() => {
            setPage(page === 1 ? page + 2 : page + 1);
            setLimit(3);
          }}
          style={{ display: "inline-block" }}
        >
          {clientStoryRawData.has_more && (
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="Load More"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="Load More"
            />
          )}
        </div>
      </div>
      <div className="mt-50 body-container">
        <Megaview MegaviewData={megaviewData.data} />
      </div>
      {articleData && articleData.length > 0 && (
        <div className="mt-70">
          <div className="body-container">
            <h3 className="title-header ttc">
              <span className="red-line3letter">Rec</span>ommended Reading
            </h3>
          </div>
          <div className="mt-50">
            <Miniview
              miniviewData={articleData}
              total={articleData.total}
              miniviewData2={miniviewDataArticle}
              quotedMiniCardData={quotedMiniCardData}
            />
          </div>
        </div>
      )}
      {articleRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setArticlePage(
                articlePage === 1 ? articlePage + 2 : articlePage + 1
              );
              setArticleLimit(3);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="READ MORE"
              tip="READ MORE"
            />
          </div>
        </div>
      )}
      <div className="body-container mt-70">
        <h3 className="title-header ttc">
          <span className="red-line3letter">Oth</span>er Services
        </h3>
      </div>
      <div className="mt-70">
        <BlurCardList componentId="1" BlurCardListData={BlurCardListData} />
      </div>
      <div className="mt-70"></div>
      <TwoColumnContactInfo
        TwoColumnContactInfoData={TwoColumnContactInfoData}
      />
    </Layout>
  );
};

export default WebApplicationDevelopment;
